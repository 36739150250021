@import "../node_modules/bulma/sass/utilities/initial-variables";
@import "../node_modules/bulma/sass/utilities/functions";

$blue: #0178bb;
$blue-invert: #fff;
$family-muli: 'Muli', sans-serif;

$primary: $blue;
$primary-invert: $blue-invert;
$link: $blue;
$link-invert: $blue-invert;

$family-primary: $family-muli;

@import "../node_modules/bulma/bulma";

//==============================================================================

$navbar-height-withborder: 3.50rem;

html {
  overflow-y: hidden;
}

html.scroll {
  overflow-y: visible;
}

body.has-navbar-fixed-top {
  padding-top: $navbar-height-withborder !important;
}

templates {
  display: none;
}

.wait, .wait * { 
  cursor: wait !important; 
}

nav.navbar {
  border-bottom: 6px solid #0178bb;
}

section.prompt {
  background-color: #ffffff;
  background-image: url("img/bg.png");
  background-position: right bottom;
  background-repeat: repeat-y;

  img.logo {
    position: absolute;
    bottom: 10%;
    right: 5%;
  }
  
  h1 {
    font-weight: lighter;
  }

  .hero.is-success {
    background: #F2F6FA;
  }
  .hero .nav, .hero.is-success .nav {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
    
}

section.main {

  .columns.is-fullheight {
    min-height: calc(100vh - ( #{$navbar-height-withborder} ) );
    max-height: calc(100vh - ( #{$navbar-height-withborder} ) );
    height: calc(100vh - ( #{$navbar-height-withborder} ) );
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    > .column {
      overflow-y: auto;
      overflow-x: hidden;
    }
    > .column.frames {
      overflow-y: scroll;
    }
  }

  div.column.menu {
    background-color: #004677;
    min-width: 225px;
  }

  .navbar-menu,aside.menu {
    .control.has-icons-right {
      padding-right: 2.25em;
    }
    .control.has-icons-right span.icon {
      pointer-events: inherit;
    }
    .control.has-icons-right span.icon svg:hover {
      color: #cc0000;
    }
    .control.has-icons-right.is-active span.icon svg:hover {
      color: #ffffff;
    }
  }

  aside.menu {
    margin-top: 0.5rem;
    margin-left: 1rem;

    ul:empty {
      display: none;
    }
    p.menu-label {
      color: $white;
    }  
    li > a {
      padding-right: 2em;
      color: $white;
      &:not(.is-active):hover {
        color: $black;
      }  
    }  
  }

}

form {
  margin-bottom: 1.5em;
  div.field + p {
    margin-top: 1.5em;
  }
  p ~ div.field {
    margin-top: 0.5em;
  }
  input.short {
    max-width: 200px;
  }
  textarea.is-static { 
    border: none;
    resize: none;
  }
}

form.framework:not(.numbered-continue) {
  counter-reset: section;                     /* Set a counter named 'section', and its initial value is 0. */
}

form.framework.numbered label.label::before {
  counter-increment: section;                 /* Increment the value of section counter by 1 */
  content: counter(section) ". ";                  /* Display the value of section counter */
}

table {
  td.vmiddle {
    vertical-align : middle;
  }
  tbody td.frame {
    padding: 0;
  }
  &.clickable {
    tbody tr.clickable {
      cursor: pointer;
      &:hover {
        background: $primary;
        color: $primary-invert;
      }
    }
  }
}

.role-admin, .role-supplier, .role-client, .role-tech {
  display: none;
}

@mixin role-add($name) {
  section.role-#{$name} {
    table.role-#{$name} {
      display: table;
    }
    th.role-#{$name}, td.role-#{$name} {
      display: table-cell;
    }
    tr.role-#{$name} {
      display: table-row;
    }
    a.button.role-#{$name} {
      display: inline-flex;
    }
    .role-#{$name} {
      display: block;
    }
  }
}

@include role-add("admin");
@include role-add("supplier");
@include role-add("client");
@include role-add("tech");

button.etiquette {
  span.icon {
    display: none;
  }
}
button.etiquette.is-primary {
  span.icon {
    display: inline;
  }
}

.nowrap {
  white-space: nowrap;
}

div.ajoutProduit {
  div.modal-card {
    height: 600px;
  }
}

@media print {
  body {
    font-size: 0.6rem;
  }
  img.logo {
    width: 40%;
  }
  .title {
    font-size: 2em;
  }
  .subtitle {
    font-size: 1.25em;
  }
  .is-hidden-print {
    display: none !important;
  }
}
